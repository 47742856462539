.ijv-icon {
    display: inline-block;
    align-self: center;
    margin: 1rem;
    width: 1rem;
    height: 1rem;
    fill: $black-base;

    &-wrapper {
        @include flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        max-width: 52rem;
        padding: 0.5rem;
    }

    &--big {
        width: 12rem;
        height: 12rem;
    }

    &--medium {
        width: 3rem;
        height: 3rem;
    }

    &--small {
        width: 2rem;
        height: 2rem;
    }
}
