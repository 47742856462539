.ijv-card {
    &-container {
        @include flex;
        position: relative;
        margin: 0.5rem 0;
        min-height: 30rem;
        border-radius: 1rem;
        z-index: 2;
        box-shadow: rgba(37, 37, 37, 0.2) 0 10px 20px,
            rgba(37, 37, 37, 0.5) 0 0 0;

        &--dir-opposite {
            flex-direction: row-reverse;
        }

        &.clr-case-1 {
            background-color: $case-color-1;
        }
        &.clr-case-2 {
            background-color: $case-color-2;
        }
        &.clr-case-3 {
            background-color: $case-color-3;
        }
        &.clr-case-4 {
            background-color: $case-color-4;
        }
        &.clr-case-5 {
            background-color: $case-color-5;
        }
        &.clr-case-6 {
            background-color: $case-color-6;
        }
        &.clr-case-7 {
            background-color: $case-color-7;
        }
        &.clr-case-8 {
            background-color: $case-color-8;
        }

        &.clr-side-1 {
            background-color: $side-color-1;
        }
        &.clr-side-2 {
            background-color: $side-color-2;
        }
        &.clr-side-3 {
            background-color: $side-color-3;
        }
        &.clr-side-4 {
            background-color: $side-color-4;
        }
        &.clr-side-5 {
            background-color: $side-color-5;
        }
        &.clr-side-6 {
            background-color: $side-color-6;
        }
        &.clr-side-7 {
            background-color: $side-color-7;
        }
    }

    &__image {
        width: 50%;

        .ijv-image-main__wrapper {
            @include flex;
            position: relative;
            padding: 4rem 0 4rem 4rem;
            height: 100%;

            &--dir-opposite {
                padding: 4rem 4rem 4rem 0;
            }
        }
    }

    &__content {
        width: 50%;
        padding: 4rem;
        color: $deep-black;

        &-title {
            @include EBGaramond;
            margin-bottom: 3rem;
            font-size: 3rem;
            line-height: 3.25rem;
            font-weight: 600;

            &--white {
                color: $white;
            }
        }

        &-summary {
            margin-bottom: 0.25rem;
            font-size: 0.875rem;
            line-height: 1.25rem;

            &--white {
                color: $white;
            }

            .summary-info {
                font-weight: bold;
                font-style: italic;
            }
        }
    }

    &-wrapper {
        @include flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        .ijv-card {
            &-container {
                position: relative;
                flex-direction: column;
                width: 50%;
                height: auto;
                border-radius: 1rem;
                z-index: 2;
                box-shadow: rgba(37, 37, 37, 0.2) 0 10px 20px,
                    rgba(37, 37, 37, 0.5) 0 0 0;

                &:first-of-type {
                    margin: 0 0.25rem 0 0;
                }

                &:last-of-type {
                    margin: 0 0 0 0.25rem;
                }
            }

            &__image {
                width: 100%;

                .ijv-image-main__wrapper {
                    @include flex;
                    height: 100%;
                    padding: 4rem 4rem 2rem 4rem;
                }
            }

            &__content {
                width: 100%;
                padding: 0 4rem 4rem 4rem;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .ijv-card {
        &-container {
            margin: 0.5rem 0;
            min-height: 30rem;
        }

        &__image {
            width: 50%;

            .ijv-image-main__wrapper {
                padding: 4rem 0 4rem 4rem;
                height: 100%;

                &--dir-opposite {
                    padding: 4rem 4rem 4rem 0;
                }
            }
        }

        &__content {
            width: 50%;
            padding: 4rem;

            &-title {
                margin-bottom: 3rem;
                font-size: 3rem;
                line-height: 3.25rem;
            }

            &-summary {
                margin-bottom: 0.25rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
            }
        }

        &-wrapper {
            margin-bottom: 0.5rem;

            .ijv-card {
                &-container {
                    position: relative;
                    flex-direction: column;
                    width: 50%;
                    height: auto;

                    &:first-of-type {
                        margin: 0 0.25rem 0 0;
                    }

                    &:last-of-type {
                        margin: 0 0 0 0.25rem;
                    }
                }

                &__image {
                    width: 100%;

                    .ijv-image-main__wrapper {
                        padding: 4rem 4rem 2rem 4rem;
                    }
                }

                &__content {
                    width: 100%;
                    padding: 0 4rem 4rem 4rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-card {
        &-container {
            display: block;
            position: relative;
            margin: 0.5rem 0;
            min-height: 30rem;
        }

        &__image {
            width: 100%;

            .ijv-image-main__wrapper {
                padding: 4rem 4rem 2rem 4rem;
                height: 100%;

                &--reverse {
                    padding: 4rem 4rem 2rem 4rem;
                }
            }
        }

        &__content {
            width: 100%;
            padding: 0 4rem 4rem 4rem;
        }

        &-wrapper {
            display: block;
            margin-bottom: 0.5rem;

            .ijv-card {
                &-container {
                    width: 100%;

                    &:first-of-type {
                        margin: 0;
                    }

                    &:last-of-type {
                        margin: 0.5rem 0 0 0;
                    }
                }

                &__image {
                    .ijv-image-main__wrapper {
                        padding: 4rem 4rem 2rem 4rem;
                    }
                }

                &__content {
                    padding: 0 4rem 4rem 4rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-card {
        &-container {
            margin: 2rem 0;
            min-height: auto;
        }

        &__image {
            .ijv-image-main__wrapper {
                padding: 2rem 2rem 1rem 2rem;

                &--reverse {
                    padding: 2rem 2rem 1rem 2rem;
                }
            }
        }

        &__content {
            padding: 0 2rem 2rem 2rem;

            &-title {
                margin-bottom: 1.5rem;
                font-size: 2.5rem;
                line-height: 2.75rem;
            }

            &-summary {
                margin-bottom: 0.25rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
            }
        }

        &-wrapper {
            margin-bottom: 2rem;
            
            .ijv-card {
                &-container {
                    width: 100%;

                    &:first-of-type {
                        margin: 0;
                    }

                    &:last-of-type {
                        margin: 2rem 0 0 0;
                    }
                }

                &__image {
                    .ijv-image-main__wrapper {
                        padding: 2rem 2rem 1rem 2rem;
                    }
                }

                &__content {
                    padding: 0 2rem 2rem 2rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-card {
        &__content {
            min-height: auto;

            &-title {
                font-size: 2rem;
                line-height: 2.25rem;
            }

            &-summary {
                margin-bottom: 0.25rem;
                font-size: 0.875rem;
                line-height: 1.125rem;
            }
        }
    }
}
