.ijv-about {
    .about {
        &__container {
            @include flex;
            justify-content: center;
            margin: 0 auto;
            padding: 0.5rem 0 4rem 0;
            max-width: 52rem;
        }

        &__bio,
        &__extra {
            width: 100%;
        }

        &__bio {
            &__bio-wrapper {
                .about-image {
                    @include flex;
                    justify-content: center;
                    position: relative;
                    margin: 2rem 0 1rem 0;
                    height: auto;

                    &-wrapper {
                        @include flex;
                        @include flex-align-items-center;
                        justify-content: center;
                        position: relative;
                        margin: 0 auto;
                        max-width: 350px;

                        .shape-background-about {
                            position: relative;
                            width: 350px;
                            height: 350px;
                        }

                        .headshot {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 250px;
                            height: 250px;
                            border-radius: 50%;
                            background-color: lighten($case-color-1, 15%);
                            overflow: hidden;
                            box-shadow: 0 15px 30px 0 rgba(37, 37, 37, 0.11),
                                0 5px 15px 0 rgba(37, 37, 37, 0.08);
                        }
                    }
                }
            }
        }

        &__extra {
            border-top: $default-border;

            &-content {
                padding: 1rem 2rem;
            }

            .bottom {
                @include flex;
                justify-content: center;
                height: 10rem;
                padding: 1rem 0;

                .bubbles {
                    align-self: flex-end;
                    position: relative;
                    width: 100%;

                    .bubbles-text {
                        margin-bottom: 0;
                        font-size: 1.5rem;
                        font-weight: 700;
                        font-smoothing: antialiased;
                        text-align: center;
                        letter-spacing: 1px;
                        color: $white;
                        text-shadow: 0 -2px 0 $black-base, 0 -2px 0 $black-base,
                            0 2px 0 $black-base, 0 2px 0 $black-base,
                            -2px 0 0 $black-base, 2px 0 0 $black-base,
                            -2px 0 0 $black-base, 2px 0 0 $black-base,
                            -2px -2px 0 $black-base, 2px -2px 0 $black-base,
                            -2px 2px 0 $black-base, 2px 2px 0 $black-base,
                            -2px 4px 0 $black-base, 0 4px 0 $black-base,
                            2px 4px 0 $black-base,
                            0 6px 2px rgba($black-base, 0.2);
                    }

                    .dot {
                        position: absolute;
                        margin-top: -5rem;
                        width: 10px;
                        height: 10px;
                        z-index: 2;
                    }
                }
            }
        }
    }

    &__title {
        display: inline-block;
        margin-bottom: 0;
        padding: 0.5rem 1rem;
        border-radius: 0 0 0.25rem 0.25rem;
        background-color: $deep-black;
        font-size: 1.25rem;
        font-weight: 900;
        color: $white;
        text-transform: capitalize;

        &--dark {
            border-bottom: $default-border;
            background-color: $deep-black;
            text-transform: initial;
            color: $white;
        }

        &--small {
            padding: 1rem 0 0 1rem;
            background-color: transparent;
            font-size: 0.875rem;
            font-weight: 600;
            color: $black-base;
        }
    }

    .about-pitch {
        margin: 0 auto;
        padding-top: 1rem;
        width: 100%;
        max-width: 37.5rem;
    }

    &__content {
        margin-bottom: 0;
        padding: 1rem 0;
        font-size: 1.125rem;
        line-height: 1.5rem;

        &--small {
            margin-bottom: 0;
            padding: 0.5rem 1rem 1rem 1rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-style: italic;
            color: $text-base;
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-about {
        .about {
            &__container {
                padding: 0.5rem 1rem 4rem 1rem;
            }

            &__bio {
                margin: 0.5rem 0;

                &__bio-wrapper {
                    grid-template-columns: 50% 1fr;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-about {
        .about {
            &__container {
                padding: 0.5rem 1rem 3rem 1rem;
            }

            &__bio {
                &__bio-wrapper {
                    display: block;
                    margin: 0 1rem;
                }
            }

            &__extra {
                &-content {
                    padding: 0 1rem 1rem 1rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-about {
        .about {
            &__container {
                padding: 0.5rem;
            }

            &__bio {
                margin: 0.5rem 0;

                &__bio-wrapper {
                    .about-image {
                        &-wrapper {
                            max-width: 300px;

                            .shape-background-about {
                                width: 300px;
                                height: 300px;
                            }

                            .headshot {
                                width: 200px;
                                height: 200px;
                                transform: translateX(20px);
                            }
                        }
                    }
                }
            }

            &__extra {
                margin: 0.5rem 0;

                &-content {
                    .bottom {
                        .bubbles {
                            width: 75%;
                            padding-bottom: 1rem;
                        }
                    }
                }
            }

            &__block {
                margin-top: 0.5rem;
                margin-bottom: 0;
            }
        }
    }
}
