.animation-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.float {
    position: absolute;
    @include animation-browsers(gravity 5s infinite);

    .ijv-icon {
        width: 2.25rem;
        height: 2.25rem;
        fill: $white;

        &__light {
            opacity: 1;
        }

        &__light--bright {
            fill: $bright-float-color;
        }

        &__light--mid {
            opacity: 0.65;
        }

        &__light--low {
            opacity: 0.4;
        }

        &__light__extralow {
            opacity: 0.1;
        }
    }

    &:nth-of-type(1) {
        top: 66%;
        left: 13%;
        @include animation-delay(-0.9s);
    }

    &:nth-of-type(2) {
        top: 31%;
        left: 33%;
        @include animation-delay(-4.8s);
    }

    &:nth-of-type(3) {
        top: 76%;
        left: 38%;
        @include animation-delay(-4s);
    }

    &:nth-of-type(4) {
        top: 21%;
        left: 14%;
        @include animation-delay(-2.8s);
    }

    &:nth-of-type(5) {
        top: 58%;
        left: 56%;
        @include animation-delay(-2.15s);
    }

    &:nth-of-type(6) {
        top: 8%;
        left: 72%;
        @include animation-delay(-1.9s);
    }

    &:nth-of-type(7) {
        top: 31%;
        left: 58%;
        @include animation-delay(-0.65s);
    }

    &:nth-of-type(8) {
        top: 69%;
        left: 81%;
        @include animation-delay(-0.4s);
    }

    &:nth-of-type(9) {
        top: 15%;
        left: 32%;
        @include animation-delay(-4.1s);
    }

    &:nth-of-type(10) {
        top: 13%;
        left: 45%;
        @include animation-delay(-3.65s);
    }

    &:nth-of-type(11) {
        top: 55%;
        left: 27%;
        @include animation-delay(-2.25s);
    }

    &:nth-of-type(12) {
        top: 49%;
        left: 53%;
        @include animation-delay(-2s);
    }

    &:nth-of-type(13) {
        top: 34%;
        left: 49%;
        @include animation-delay(-1.55s);
    }

    &:nth-of-type(14) {
        top: 33%;
        left: 86%;
        @include animation-delay(-0.95s);
    }

    &:nth-of-type(15) {
        top: 28%;
        left: 25%;
        @include animation-delay(-4.45s);
    }

    &:nth-of-type(16) {
        top: 39%;
        left: 10%;
        @include animation-delay(-3.35s);
    }

    &:nth-of-type(17) {
        top: 77%;
        left: 24%;
        @include animation-delay(-2.3s);
    }

    &:nth-of-type(18) {
        top: 2%;
        left: 47%;
        @include animation-delay(-1.75s);
    }

    &:nth-of-type(19) {
        top: 71%;
        left: 66%;
        @include animation-delay(-1.25s);
    }

    &:nth-of-type(20) {
        top: 31%;
        left: 76%;
        @include animation-delay(-0.65s);
    }

    &:nth-of-type(21) {
        top: 46%;
        left: 38%;
        @include animation-delay(-0.35s);
    }

    &:nth-of-type(22) {
        top: 3%;
        left: 19%;
        @include animation-delay(-4.3s);
    }

    &:nth-of-type(23) {
        top: 3%;
        left: 6%;
        @include animation-delay(-4s);
    }

    &:nth-of-type(24) {
        top: 77%;
        left: 4%;
        @include animation-delay(-3.75s);
    }

    &:nth-of-type(25) {
        top: 1%;
        left: 71%;
        @include animation-delay(-3.3s);
    }

    &:nth-of-type(26) {
        top: 23%;
        left: 63%;
        @include animation-delay(-2.1s);
    }

    &:nth-of-type(27) {
        top: 81%;
        left: 45%;
        @include animation-delay(-1.75s);
    }

    &:nth-of-type(28) {
        top: 60%;
        left: 42%;
        @include animation-delay(-1.45s);
    }

    &:nth-of-type(29) {
        top: 29%;
        left: 93%;
        @include animation-delay(-1s);
    }

    &:nth-of-type(30) {
        top: 52%;
        left: 68%;
        @include animation-delay(-0.7s);
    }
}

@include animation-keyframes(gravity) {
    0% {
        transform: translateY(0%) rotate(-55deg);
    }

    50% {
        transform: translateY(300%) rotate(55deg);
    }

    100% {
        transform: translateY(0%) rotate(-55deg);
    }
}

@include media-breakpoint-down(md) {
    .float {
        .ijv-icon {
            width: 2rem;
            height: 2rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .float {
        .ijv-icon {
            width: 1.75rem;
            height: 1.75rem;
        }
    }
}

@include media-breakpoint-down(xs) {
    .float {
        .ijv-icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
