.ijv-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;

    &--logo {
        width: 2.5rem;

        @include media-breakpoint-down(xs) {
            width: 2rem;
        }
    }

    &__container {
        @include flex;
        justify-content: center;
        align-items: center;
        max-width: 45rem;
    }

    &__intro-cover {
        height: auto;
    }

    &__case-header {
        height: 35rem;

        @include media-breakpoint-down(lg) {
            height: 30rem;
        }

        @include media-breakpoint-down(md) {
            height: 25rem;
        }

        @include media-breakpoint-down(sm) {
            height: 20rem;
        }

        @include media-breakpoint-down(xs) {
            height: 15rem;
        }
    }

    &__case-content {
        align-self: center;
        width: 75%;

        &--medium {
            align-self: center;
            margin: 0 0 0.25rem;
            width: 100%;
        }

        &--small {
            align-self: center;
            margin: 0 0 0.25rem;
            width: 75%;

            @include media-breakpoint-down(lg) {
                width: 75%;
            }

            @include media-breakpoint-down(md) {
                width: 60%;
            }

            @include media-breakpoint-down(sm) {
                width: 60%;
            }

            @include media-breakpoint-down(xs) {
                width: 75%;
            }
        }

        &--extrasmall {
            align-self: center;
            width: 25%;

            @include media-breakpoint-down(lg) {
                width: 25%;
            }

            @include media-breakpoint-down(md) {
                width: 25%;
            }

            @include media-breakpoint-down(sm) {
                width: 25%;
            }

            @include media-breakpoint-down(xs) {
                width: 25%;
            }
        }
    }

    &__case-codepen {
        align-self: center;
        width: 50%;

        @include media-breakpoint-down(lg) {
            width: 50%;
        }

        @include media-breakpoint-down(md) {
            width: 85%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    &__intro-gif {
        margin: 0;
    }

    &__case-gif {
        margin-bottom: 0.25rem;
        width: 100%;
    }

    &__about {
        max-width: 100%;
        height: auto;
        vertical-align: top;
    }
}

.ijv-codepen__last-one {
    margin-bottom: 6rem;
}

.ijv-footer-image {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 45%;
    padding: 0.5rem 1rem;
    border-radius: 0 0 0 0.5rem;
    background-color: #f5f5f5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.25;
    font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif;
    color: $text-base;
    z-index: 2;

    @include media-breakpoint-down(md) {
        max-width: 65%;
    }

    @include media-breakpoint-down(xs) {
        max-width: 85%;
    }
}
