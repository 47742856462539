.ijv-case {
    position: relative;
    margin: 0 auto;
    padding: 1rem 3rem;
    z-index: 1;

    &__title {
        width: 100%;
        margin-top: 1.25rem;
        margin-bottom: 0.5rem;
        padding: 1.5rem 0 1rem 0;
        font-size: 3rem;
        line-height: 3.25rem;
        font-weight: 600;
        color: $deep-black;
        text-align: left;
        text-transform: capitalize;

        &--white {
            color: $white;
        }
    }

    &__summary {
        margin-bottom: 0;
        width: 100%;
        padding-bottom: 2rem;
        font-size: 1.5rem;
        line-height: 1.625rem;
        font-weight: 300;
        color: $deep-black;
        text-align: left;

        &--white {
            color: $white;
        }
    }

    &__picture-frame {
        position: relative;
        margin-bottom: 2.5rem;
        width: 100%;
    }

    &__body {
        @include flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
    }

    &__section {
        position: relative;
        margin: 2rem 0 1rem 0;
        border-top: $default-border;
        width: 100%;
        max-width: 37.5rem;
        padding-top: 3rem;

        &--top {
            margin: 0 0 1rem 0;
            border-top: none;
            padding-top: 0;
        }

        &--multiple {
            border-top: none;
            padding-top: 0;
        }

        &__title {
            @include Inter;
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            margin-top: -3px;
            margin-bottom: 0;
            border-top: $default-border;
            border-bottom: $default-border;
            padding: 0.5rem 1rem;
            border-radius: 0 0 0.25rem 0.25rem;
            background-color: $deep-black;
            font-size: 1.25rem;
            font-weight: 900;
            color: $white;

            &--light {
                background-color: $white;
                color: $deep-black;
            }
        }

        &__wrapper {
            @include flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 1.5rem;
        }

        &-end-of-case {
            margin-bottom: 6rem;
        }
    }

    &__header {
        @include EBGaramond;
        margin: 0 auto;
        max-width: 52rem;
        font-size: 2rem;
        font-weight: 700;

        &-top {
            margin-top: 0;
            padding: 0.5rem 0;
        }

        &--underline {
            border-bottom: $default-border;
        }

        &--end-of-case {
            margin-bottom: 6rem;
        }
    }

    &__subheader {
        margin-top: 1.25rem;
        width: 100%;
        max-width: 37.5rem;
        font-size: 1.125rem;
        font-weight: 700;
        font-style: italic;
    }

    &__text {
        width: 100%;
        max-width: 37.5rem;
        font-size: 1.125rem;
        line-height: 2rem;
        margin-bottom: 0;

        &--summary {
            font-size: 0.875rem;

            &:last-of-type {
                margin-top: -0.5rem;
            }

            .summary-info {
                font-weight: bold;
                font-style: italic;
            }
        }

        &--listed-items {
            margin-bottom: 0;
            margin-top: 0;
        }

        &--after-list {
            margin-top: 0.5rem;
        }

        &--lastone,
        &--lastlink {
            margin-bottom: 6rem;
        }

        &--lastlink {
            text-align: center;
        }
    }

    &__footnote {
        margin-bottom: 1.5rem;
        width: 100%;
        max-width: 37.5rem;
        font-size: 0.75rem;
        font-weight: 300;
        font-style: italic;
        text-align: center;

        &--end-of-case {
            margin-bottom: 6rem;
        }
    }

    &__list {
        margin-bottom: 0;
        margin-left: 1rem;
        width: 100%;
        max-width: 37.5rem;
        font-size: 1.125rem;

        &-item {
            margin-bottom: 1rem;
        }
    }
}

@include media-breakpoint-down(lg) {
    .ijv-case {
        width: auto;
        padding: 1rem 2.5rem;

        &__summary {
            line-height: 1.5rem;
        }

        &__header {
            &-top {
                margin-top: 0;
            }
        }

        &__list {
            margin-left: 1rem;
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-case {
        padding: 1rem 1.5rem;

        &__summary {
            line-height: 1.625rem;
        }

        &__header {
            &-top {
                margin-top: 0;
            }
        }

        &__list {
            margin-left: 1rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-case {
        padding: 0 1.25rem;

        &__title {
            padding: 1rem 0 0.5rem 0;
            font-size: 2.5rem;
            line-height: 2.75rem;
        }

        &__summary {
            padding-bottom: 1.5rem;
            font-size: 1.25rem;
            line-height: 1.5rem;
        }

        &__header {
            &-top {
                margin-top: -1rem;
            }
        }

        &__list {
            margin-left: 0;
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-case {
        padding: 0 1.25rem;

        &__title {
            padding: 1rem 0 0.5rem 0;
        }

        &__summary {
            padding-bottom: 1.5rem;
            font-size: 1.25rem;
            line-height: 1.375rem;
        }

        &__header {
            word-wrap: break-word;

            &-top {
                margin-top: -1rem;
            }
        }

        &__list {
            margin-left: 0;

            &-item {
                line-height: 1.625rem;
            }
        }
    }
}
