// Font mixins
@mixin EBGaramond {
    font-family: 'EB Garamond', -apple-system, BlinkMacSystemFont, Garamond, Georgia, Times, "Times New Roman", serif;
}
@mixin Inter {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
}
@mixin PressStart2P {
    font-family: 'Press Start 2P', -apple-system, BlinkMacSystemFont, "Source Code Pro", "Roboto Mono", monospace;
}

// Flexbox mixins
@mixin flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
@mixin flex-align-items-center {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

// Transform mixins
@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    transform: rotate($degrees);
}
@mixin move-vertical($percentage) {
    -webkit-transform: translateY($percentage);
    -ms-transform: translateY($percentage);
    transform: translateY($percentage);
}

// Animation mixins
@mixin animation-browsers($animation) {
    -webkit-animation: $animation;
    -moz-animation: $animation;
    -ms-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}
@mixin animation-browsers-double($animation1, $animation2) {
    -webkit-animation: $animation1, $animation2;
    -moz-animation: $animation1, $animation2;
    -ms-animation: $animation1, $animation2;
    -o-animation: $animation1, $animation2;
    animation: $animation1, $animation2;
}
@mixin animation-keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}
@mixin animation-delay($time) {
    -webkit-animation-delay: $time;
    -moz-animation-delay: $time;
    -ms-animation-delay: $time;
    -o-animation-delay: $time;
    animation-delay: $time;
}

// Utilities mixins
@mixin margin-sides-same($value) {
    margin-left: $value;
    margin-right: $value;
}
@mixin margin-topbot-same($value) {
    margin-top: $value;
    margin-bottom: $value;
}
