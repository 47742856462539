.ijv-footer-wrapper {
    position: relative;
    grid-area: ft;
    z-index: 1;
    transition: all 0.3s;

    .ijv-footer-container {
        position: absolute;
        width: 100vw;
        background-color: $deep-black;

        .ijv-footer {
            color: $white;

            &__top {
                margin-bottom: 1rem;
                padding: 7.5rem 0 8rem 0;
                background-color: $main-background-color;
                color: $deep-black;

                &__header {
                    padding-top: 0.25rem;
                    padding-bottom: 1.5rem;
                    text-align: center;
                    font-weight: 800;
                }

                &__message {
                    margin-bottom: 0.5rem;
                    padding: 0 1rem 0;
                    font-size: 1rem;
                    font-weight: 300;
                    line-height: 1.125rem;
                    text-align: center;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            &__center {
                padding: 2rem;
                background-color: $deep-black;

                &__list {
                    @include flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-bottom: 0;
                    padding-left: 0;
                    list-style: none;
                    background-color: $deep-black;
                }

                &__item {
                    &__btn-social {
                        @include flex;
                        justify-content: center;
                        align-items: center;
                        margin: 1rem;
                        width: 3.5rem;
                        height: 3.5rem;
                        border-radius: 50%;
                        background-color: $main-background-color;
                        -webkit-transition: box-shadow 0.15s ease;
                        -moz-transition: box-shadow 0.15s ease;
                        -ms-transition: box-shadow 0.15s ease;
                        -o-transition: box-shadow 0.15s ease;
                        transition: box-shadow 0.15s ease;
                        box-shadow: 0 0 0 0 $main-background-color;

                        &:active,
                        &:focus,
                        &:hover {
                            cursor: pointer;
                            box-shadow: 0 0 0 3px $main-background-color;
                        }

                        .ijv-icon {
                            color: $deep-black;
                            width: 1.75rem;
                            height: 1.75rem;
                        }
                    }
                }
            }

            &__bottom {
                padding-bottom: 2.5rem;
                background-color: $deep-black;

                &__copyright {
                    padding: 0 2rem;
                    font-size: 0.687rem;
                    text-align: center;
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-footer-wrapper {
        .ijv-footer-container {
            .ijv-footer {
                &__top {
                    &__header {
                        font-size: 1.75rem;
                    }
                }
            }
        }
    }
}
