.ijv-pattern-dotted {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: $white;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScgLz4KICA8Y2lyY2xlIGN4PScxLjUnIGN5PScxLjUnIHI9JzEuNScgZmlsbD0nYmxhY2snLz4KPC9zdmc+Cg==");
    background-repeat: repeat;
    z-index: 1;
    opacity: 0.4;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 50%));
    transform: scaleX(-1);

    &--reverse {
        top: unset;
        bottom: 4.25rem;
        height: 35%;
        transform: scaleY(-1);
    }
}

.pattern-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30rem;
    z-index: 0;

    .ijv-pattern-shape {
        width: 100%;
        height: 100%;

        &.clr-case-1 {
            background-color: $case-color-1;
        }
        &.clr-case-2 {
            background-color: $case-color-2;
        }
        &.clr-case-3 {
            background-color: $case-color-3;
        }
        &.clr-case-4 {
            background-color: $case-color-4;
        }
        &.clr-case-5 {
            background-color: $case-color-5;
        }
        &.clr-case-6 {
            background-color: $case-color-6;
        }
        &.clr-case-7 {
            background-color: $case-color-7;
        }
        &.clr-case-8 {
            background-color: $case-color-8;
        }
        &.clr-side-1 {
            background-color: $side-color-1;
        }
        &.clr-side-2 {
            background-color: $side-color-2;
        }
        &.clr-side-3 {
            background-color: $side-color-3;
        }
        &.clr-side-4 {
            background-color: $side-color-4;
        }
        &.clr-side-5 {
            background-color: $side-color-5;
        }
        &.clr-side-6 {
            background-color: $side-color-6;
        }
        &.clr-side-7 {
            background-color: $side-color-7;
        }
    }
}

@include media-breakpoint-down(md) {
    .pattern-wrapper {
        height: 25rem;
    }
}

@include media-breakpoint-down(sm) {
    .pattern-wrapper {
        height: 20rem;
    }
}
