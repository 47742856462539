$black-base: #252525;
$deep-black: #1a1f1f;
$white: #fff;
$brand-first: #00ced1;

$case-color-1: #b3296d;
$case-color-2: #eccdd3;
$case-color-3: #a9597f;
$case-color-4: #0c486d;
$case-color-5: #a4c8e2;
$case-color-6: #654c54;
$case-color-7: #2d3436;
$case-color-8: #d87737;

$side-color-1: #3b4856;
$side-color-2: #94836d;
$side-color-3: #f1e1c6;
$side-color-4: #68334a;
$side-color-5: #e39e22;
$side-color-6: #0082a1;
$side-color-7: #202628;
$side-color: #008b8b;
$main-background-color: #f5ead9;
$quote-complement-color: #778899;
$bright-float-color: #e43d88;

$default-radius: 0;
$default-border: 3px solid $deep-black;

$text-base: #333;
