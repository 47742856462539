.ijv-paginator {
    position: relative;
    margin: 0 auto;
    border-top: 3px solid $deep-black;
    width: 90%;
    padding: 3rem 0.75rem 2rem 0.75rem;

    &__title {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        margin-top: -3px;
        margin-bottom: 0;
        border-top: 3px solid $deep-black;
        border-bottom: 3px solid $deep-black;
        border-radius: 0 0 0.25rem 0.25rem;
        padding: 0.5rem 1rem;
        background-color: $deep-black;
        font-size: 1.125rem;
        font-weight: 900;
        color: $white;
    }

    &__wrapper {
        @include flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3.25rem 0 2rem 0;
    }

    &-case {
        &__container {
            margin-bottom: 2.5rem;
            width: 48rem;
            height: auto;
            border-radius: 0.5rem;
            box-shadow: rgba(37, 37, 37, 0.2) 0 10px 20px,
                rgba(37, 37, 37, 0.5) 0 0 0;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &__wrapper {
            @include flex;
            @include flex-align-items-center;
            border-radius: 0.5rem;
            border: 3px solid transparent;

            .ijv-image {
                border-radius: 0.5rem 0 0 0.5rem;
            }

            &.clr-case-1 {
                background-color: $case-color-1;
            }
            &.clr-case-2 {
                background-color: $case-color-2;
            }
            &.clr-case-3 {
                background-color: $case-color-3;
            }
            &.clr-case-4 {
                background-color: $case-color-4;
            }
            &.clr-case-5 {
                background-color: $case-color-5;
            }
            &.clr-case-6 {
                background-color: $case-color-6;
            }
            &.clr-case-7 {
                background-color: $case-color-7;
            }
            &.clr-case-8 {
                background-color: $case-color-8;
            }

            &--direction {
                flex-direction: row-reverse;

                .ijv-image {
                    border-radius: 0 0.5rem 0.5rem 0;
                }
            }
        }

        &__image {
            display: inline-block;
            width: 35%;
        }

        &__text {
            display: inline-block;
            width: 65%;
            padding: 0 3rem;

            .case-title {
                @include EBGaramond;
                margin-bottom: 0;
                text-align: left;
                text-transform: capitalize;
                padding: 0;
                font-size: 1.75rem;
                font-weight: 600;
                line-height: 2rem;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .ijv-paginator {
        padding: 3rem 0.75rem 2rem 0.75rem;

        &-case {
            &__container {
                flex-direction: unset;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-paginator {
        padding: 3rem 0.75rem 2rem 0.75rem;

        &-case {
            &__container {
                flex-direction: unset;
                width: 36rem;
            }

            &__text {
                padding: 0 1.5rem;

                .case-title {
                    font-size: 1.5rem;
                    line-height: 1.75rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-paginator {
        padding: 1.75rem 0.75rem 1rem 0.75rem;

        &-case {
            &__container {
                @include margin-topbot-same(1rem);
                flex-direction: unset;
                width: 100%;
            }

            &__text {
                padding: 1.5rem;

                .case-title {
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-paginator {
        padding: 1.5rem 0.75rem 1rem 0.75rem;

        &-case {
            &__container {
                @include margin-topbot-same(1rem);
                flex-direction: column;
                width: 100%;
            }

            &__image {
                width: 100%;
            }

            &__text {
                width: 100%;
                padding: 0.5rem;

                .case-title {
                    font-size: 0.875rem;
                    line-height: 1rem;
                }
            }
        }
    }
}
