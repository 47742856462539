.ijv-body {
    &__container-main {
        .ijv-sides {
            z-index: 1;

            .ijv-hero--sides {
                margin-bottom: 6rem;
            }

            .intro-wrapper {
                @include flex;
                @include flex-align-items-center;
                justify-content: center;
                position: relative;
                width: 50%;
                max-width: 52rem;
                height: 14rem;
                z-index: 2;

                &:before,
                &:after {
                    position: absolute;
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 1rem;
                }

                &:before {
                    border: 5px solid $deep-black;
                    z-index: 2;
                }

                &:after {
                    margin-left: -1.5rem;
                    margin-top: 1.5rem;
                    border-radius: 1rem;
                    background-color: $main-background-color;
                }

                .intro-title {
                    margin-bottom: 0;
                    font-size: 4rem;
                    font-weight: 900;
                    color: $deep-black;
                    z-index: 2;

                    &--big {
                        font-size: 4.5rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-body {
        &__container-main {
            .ijv-sides {
                .intro-wrapper {
                    width: 60%;
                    height: 10rem;

                    .intro-title {
                        font-size: 3rem;

                        &--big {
                            font-size: 3.5rem;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-body {
        &__container-main {
            .ijv-sides {
                .intro-wrapper {
                    width: 55%;

                    .intro-title {
                        font-size: 2.5rem;

                        &--big {
                            font-size: 3rem;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-body {
        &__container-main {
            .ijv-sides {
                .intro-wrapper {
                    width: 75%;
                    height: 8rem;

                    .intro-title {
                        font-size: 2rem;

                        &--big {
                            font-size: 2.5rem;
                        }
                    }
                }
            }
        }
    }
}
