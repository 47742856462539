.ijv-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 6rem;
    grid-area: hd;
    z-index: 20;
    transition: transform 0.45s ease;

    &__nav {
        @include flex;
        @include flex-align-items-center;
        justify-content: space-between;
        height: 100%;
        background-color: $white;
        text-transform: uppercase;
        z-index: 21;
    }

    &__logo {
        margin-left: 1rem;
        padding: 1rem;
        transition-duration: 0.3s;

        &:active,
        &:focus,
        &:hover {
            background-color: transparent;
        }
    }

    &__right-nav {
        &-list {
            @include flex;
            @include flex-align-items-center;
            justify-content: center;
            margin-bottom: 0;
            padding: 0 1rem;
            list-style: none;

            .active {
                .ijv-navbar__right-nav-list__link {
                    box-shadow: inset 0 -3px 0 $black-base;
                    padding-bottom: 0.125rem;
                    margin-bottom: 1rem;

                    &:after {
                        width: 0;
                    }
                }
            }

            &__link {
                @include margin-topbot-same(1.125rem);
                position: relative;
                display: block;
                padding: 0;
                font-size: 0.875rem;
                color: $black-base;
                font-weight: 700;
                text-transform: initial;

                &:after {
                    position: absolute;
                    display: block;
                    right: 0;
                    margin-top: -1px;
                    width: 0;
                    height: 3px;
                    content: "";
                    background: #fff;
                    transition: width 0.25s ease;
                }

                &:focus,
                &:hover {
                    color: $black-base;
                }

                &:hover:after {
                    left: 0;
                    width: 100%;
                    background-color: $black-base;
                }

                &:first-of-type {
                    margin-left: 1.5rem;
                }

                &:last-of-type {
                    margin-right: 1.5rem;
                }
            }
        }

        &-menu {
            display: none;
            height: 100%;

            &__btn {
                position: relative;
                height: inherit;
                padding: 1.062rem;
                background-color: $white;
                border: none;
                transition-duration: 0.3s;
                cursor: pointer;

                &:active {
                    background-color: $white;
                }

                &:focus,
                &:hover {
                    background-color: transparent;
                }

                &__text {
                    margin-right: 2.5rem;
                    left: 0;
                    font-size: 0.875rem;
                    color: $black-base;
                    font-weight: 700;
                    text-transform: uppercase;
                }

                &__bar {
                    &,
                    &::before,
                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 100%;
                        height: 3px;
                        background: $black-base;
                        content: "";
                        transition: 0.2s background linear 0.1s,
                            0.2s top linear 0.2s, 0.2s transform linear;
                    }

                    & {
                        margin-top: -0.075rem;
                        margin-right: 1rem;
                        width: 25%;
                    }

                    &::before {
                        top: -0.5rem;
                    }

                    &::after {
                        top: 0.5rem;
                    }

                    &::before,
                    &::after {
                        @include rotate(0deg);
                    }

                    .active & {
                        background: 0;

                        &::before {
                            @include rotate(45deg);
                        }

                        &::after {
                            @include rotate(-45deg);
                        }

                        &::before,
                        &::after {
                            top: 0;
                        }

                        &,
                        &::before,
                        &::after {
                            transition: 0.2s background linear 0.1s,
                                0.2s top linear, 0.2s transform linear 0.2s;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-navbar {
        &__right-nav {
            &-list {
                display: none;
            }

            &-menu {
                display: block;
                margin-right: 1rem;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-navbar {
        height: 4rem;

        &__blck {
            @include margin-sides-same(0.5rem);
            height: 0.5rem;
        }

        &__logo {
            margin-left: 0.5rem;
            padding: 0.5rem;
        }

        &__right-nav {
            &-menu {
                margin-right: 0.5rem;

                &__btn {
                    padding: 0.562rem 0.75rem;

                    &__text {
                        font-size: 0.812rem;
                    }
                }
            }
        }
    }
}

.navbar-slide-up {
    transform: translateY(-6rem);
}
.nav-overflow {
    overflow: hidden;
}
