.ijv-hero {
    @include flex;
    @include flex-align-items-center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 80vh;
    color: $deep-black;
    background-color: $main-background-color;
    z-index: 3;

    .hero-container {
        position: relative;
        padding: 0 2rem;
        z-index: 4;

        .hero-title,
        .hero-role {
            margin-bottom: 0;
            text-transform: capitalize;
            line-height: 1;
        }

        .hero-title {
            @include EBGaramond;
            font-size: 1.75rem;
        }

        .hero-role {
            @include Inter;
            margin-top: 0.5rem;
            font-size: 3rem;
            font-weight: 800;
        }

        .hero-pitch {
            @include Inter;
            margin-top: 2rem;
            font-size: 1.25rem;
            font-weight: 300;

            .pitch-text {
                margin-bottom: 0;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .hero-shapes {
        @include flex;
        justify-content: flex-end;
        align-self: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        &--image {
            width: 500px;
            height: auto;
        }
    }

    &--sides {
        background-color: transparent;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScgLz4KICA8Y2lyY2xlIGN4PScxLjUnIGN5PScxLjUnIHI9JzEuNScgZmlsbD0nYmxhY2snLz4KPC9zdmc+Cg==");
            background-repeat: repeat;
            opacity: 0.4;
            z-index: -1;
        }
    }
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .ijv-hero {
        justify-content: center;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .ijv-hero {
        justify-content: flex-start;

        &--sides {
            justify-content: center;
        }
    }
}

@include media-breakpoint-down(lg) {
    .ijv-hero {
        justify-content: flex-start;

        &--sides {
            justify-content: center;
        }

        .hero-container {
            .hero-title {
                font-size: 1.75rem;
            }

            .hero-role {
                font-size: 3rem;
            }

            .hero-pitch {
                margin-top: 2.5rem;
                font-size: 1.25rem;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-hero {
        justify-content: flex-start;

        &--sides {
            justify-content: center;
        }

        .hero-container {
            .hero-title {
                font-size: 1.5rem;
            }

            .hero-role {
                font-size: 2.5rem;
            }

            .hero-pitch {
                margin-top: 2.5rem;
                font-size: 1rem;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-hero {
        justify-content: center;

        .hero-container {
            padding: 0 1rem;

            .hero-title {
                font-size: 1.25rem;
            }

            .hero-role {
                font-size: 2rem;
            }

            .hero-pitch {
                margin-top: 1.75rem;
                font-size: 0.875rem;
            }
        }

        .hero-shapes {
            display: none;
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-hero {
        justify-content: center;

        .hero-container {
            padding: 0 1.5rem;

            .hero-title {
                font-size: 1rem;
            }

            .hero-role {
                font-size: 1.75rem;
            }

            .hero-pitch {
                margin-top: 1.75rem;
                font-size: 0.875rem;

                .pitch-text {
                    margin-bottom: 0.25rem;
                }
            }
        }

        .hero-shapes {
            display: none;
        }
    }
}
