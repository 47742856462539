.deep-sea {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 100%;
    background: $black-base;
    z-index: 3;

    .sea-wave {
        position: absolute;
        top: -94px;
        width: 12800px;
        height: 100px;
        background: url(img/wave.svg) repeat-x;
        @include animation-browsers(wave 6s linear infinite);
        transform: translate3d(0, 0, 0);
    }

    .sea-wave:nth-of-type(2) {
        top: -84px;
        @include animation-browsers-double(
            wave 6s reverse infinite,
            tide 5s ease -1.25s infinite
        );
    }

    .sea-wave:nth-of-type(3) {
        top: -74px;
        @include animation-browsers-double(
            wave 6s linear -2s infinite,
            tide 5s ease -1.25s infinite
        );
    }

    @include animation-keyframes(wave) {
        0% {
            margin-left: 0;
        }

        100% {
            margin-left: -1600px;
        }
    }

    @include animation-keyframes(tide) {
        0%,
        100% {
            transform: translate3d(0, -25px, 0);
        }

        50% {
            transform: translate3d(0, 10px, 0);
        }
    }
}

.container-bubbles {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;

    .item {
        position: absolute;
        width: 4rem;
        height: 4rem;
        border-radius: 100%;
        background-color: lighten($black-base, 5);
        box-shadow: inset -6px -6px 0 rgba($black-base, 0.2);
        z-index: 2;

        .crater {
            position: inherit;
            top: 60%;
            left: 55%;
            border-radius: 50%;
            width: 0.75rem;
            height: 0.75rem;
            background: rgba($black-base, 0.25);
            box-shadow: inset -7px -7px 0 rgba($black-base, 0.35);
            z-index: 3;

            &_2 {
                top: 35%;
                left: 65%;
                width: 0.5rem;
                height: 0.5rem;
            }
        }
    }

    $b_ease: cubic-bezier(0.47, 0, 0.745, 0.715);
    @for $i from 0 through 50 {
        $time: $i * 0.2;
        $scale: $i * 0.1;

        .shake#{$i} {
             @include animation-browsers(shake ($time + 2) + s ease 0s infinite normal);
        }
        .move#{$i} {
            @include animation-browsers(floating ($time + 5) + s $b_ease $time + s infinite normal);
            position: absolute;
            bottom: -20%;
        }
        .pos#{$i} {
            left: percentage($i * 2/100);
        }
        .scale#{$i} {
            transform: scale($scale);
            -webkit-transform: scale($scale);
        }
    }

    .switcher-color {
        @include flex;
        position: absolute;
        flex-direction: column;
        top: 1.5rem;
        right: 1.5rem;
        background-color: rgba($main-background-color, 0.5);
        padding: 0 1rem 0.75rem 0.5rem;
        border-radius: 0.25rem;
        z-index: 4;

        .color-radio-btn {
            width: 2.5rem;
            height: 2.5rem;
            margin: 0.75rem -0.5rem 0 0;
            border: 3px solid $main-background-color;
            border-radius: 50%;
            box-shadow: 0 2px 4px rgba($black-base, 0.25);
            cursor: pointer;

            &__black-base {
                background-color: lighten($black-base, 5);
            }

            &__brand-first {
                background-color: lighten($black-base, 70);
            }
        }
    }

    @keyframes shake {
        0% {
            transform: translateX(1.5rem);
        }

        50% {
            transform: translateX(-1.5rem);
        }

        100% {
            transform: translateX(1.5rem);
        }
    }

    @keyframes floating {
        0% {
            transform: translateY(0px);
            opacity: 0;
        }

        35% {
            opacity: 0;
        }

        45% {
            opacity: 1;
        }

        100% {
            transform: translateY(-1000px);
            opacity: 1;
        }
    }
}

.container-galaxy {
    position: absolute;
    padding: 0.25rem;
    width: 100%;
    height: 250px;
    z-index: 1;

    .constellation {
        position: inherit;
        width: inherit;
        height: inherit;
        border-radius: 0.5rem;
    }

    @include animation-keyframes(star) {
        0% {
            opacity: 1;
        }

        20% {
            opacity: 0.5;
        }

        40% {
            opacity: 0;
        }

        60% {
            opacity: 0.5;
        }

        80% {
            opacity: 0.75;
        }

        100% {
            opacity: 1;
        }
    }

    @for $i from 1 through 75 {
        .star-#{$i} {
            $speed: random(15) + 0.25 + s;
            $delay: -(random(25)) + s;
            $size: random(1) + 2px;
            top: random(100) + 0%;
            left: random(100) + 0%;
            width: $size;
            height: $size;
            background-color: lighten($brand-first, 40);
            @include animation-browsers(star $speed $delay infinite);
        }
    }
}
