.cover-box {
    @include flex;
    justify-content: center;
    align-content: center;
    align-self: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    width: 500px;
    height: auto;
    z-index: 3;

    &__stroke {
        position: absolute;
        top: 2.75rem;
        right: 3.5rem;
        width: 55%;
        height: auto;
        z-index: 4;
        stroke-dasharray: 556;
        stroke-dashoffset: 600;
        @include animation-browsers(cover-stroke-draw 1s linear 1s forwards);
    }

    &__big {
        position: absolute;
        top: 3rem;
        left: 4rem;
        width: 75%;
        height: auto;
        z-index: 5;
        @include animation-browsers(cover-blob-rotate 1s linear);
    }

    &__medium {
        position: absolute;
        top: 23rem;
        right: 8rem;
        width: 17%;
        height: auto;
        z-index: 5;
        opacity: 0;
        @include animation-browsers(cover-blob-jumper 0.5s linear 2.15s forwards);
    }

    &__small {
        position: absolute;
        top: 20.25rem;
        right: 5.75rem;
        width: 8%;
        height: auto;
        z-index: 5;
        opacity: 0;
        @include animation-browsers(cover-blob-jumper 0.3s linear 2s forwards);
    }
}

@keyframes cover-stroke-draw {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes cover-blob-jumper {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes cover-blob-rotate {
    0% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
