.ijv-quote {
    margin-bottom: 0;
    padding: 4rem 12rem;
    background-color: lighten($black-base, 80%);

    &__content {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.75;
        text-align: left;
        font-style: italic;
        color: $black-base;
    }

    &__author {
        font-size: 0.85rem;
        font-weight: 500;
        line-height: 1.5;
        text-align: right;
        color: $black-base;
    }

    &-case__wrapper {
        @include flex;
        justify-content: center;
        align-items: center;
        margin: 2.5rem;
        width: 50%;

        &__text {
            @include EBGaramond;
            margin-top: 1.5rem;
            width: 75%;
            border-left: 6px solid $quote-complement-color;
            padding: 3rem 3rem 3rem 4rem;
            font-size: 1.75rem;
            line-height: 2.25rem;
            text-align: left;
            font-weight: 400;
        }
    }
}

.ijv-block {
    .ijv-quote {
        &__content,
        &__author {
            font-weight: 300;
        }
    }
}

@include media-breakpoint-down(lg) {
    .ijv-quote {
        padding: 4rem 12rem;

        &__content {
            font-size: 1.25rem;
        }

        &-case__wrapper {
            margin: 2.5rem;

            &__text {
                margin: 1.5rem 0 0 0;
                width: 75%;
                padding: 3rem 3rem 3rem 4rem;
                font-size: 1.75rem;
                line-height: 2.25rem;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-quote {
        padding: 4rem 8rem;

        &__content {
            font-size: 1.15rem;
        }

        &-case__wrapper {
            margin: 2rem;
            width: 75%;

            &__text {
                margin: 1.5rem 0 0 0;
                width: 75%;
                padding: 3rem 3rem 3rem 4rem;
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-quote {
        padding: 4rem;

        &__content {
            font-size: 1rem;
            line-height: 1.5;
        }

        &__author {
            font-size: 0.85rem;
            line-height: 1.5;
        }

        &-case__wrapper {
            margin: 1rem;
            width: 100%;

            &__text {
                margin: 1.5rem 0 0 0;
                width: 100%;
                padding: 2rem 2rem 2rem 3rem;
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-quote {
        padding: 4rem 2rem;

        &__content {
            font-size: 1rem;
            line-height: 1.5;
        }

        &__author {
            font-size: 0.85rem;
            line-height: 1.5;
        }

        &-case__wrapper {
            margin: 1rem;
            width: 100%;

            &__text {
                margin: 1.5rem 0 0 0;
                width: 100%;
                padding: 2rem 2rem 2rem 3rem;
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
    }
}
