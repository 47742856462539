.ijv-overlay {
    @include move-vertical(-100%);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: transform 0.6s cubic-bezier(0.56, 0.1, 0.34, 0.91);

    .ijv-overlay--open & {
        @include move-vertical(0);
    }

    &__wrapper {
        height: 100%;
        padding-top: 5rem;
        background-color: $deep-black;
    }

    &__list {
        @include flex;
        @include flex-align-items-center;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding-left: 0;
        overflow-y: auto;
        list-style: none;

        .active {
            .ijv-overlay__link {
                box-shadow: inset 0 -3px 0 $white;
                padding-bottom: 0.125rem;
                margin-bottom: 1rem;

                &:after {
                    width: 0;
                }
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        padding: 1rem 0;
        text-align: center;
        font-size: 1.25rem;
        color: $white;
        font-weight: 700;
        text-transform: initial;

        &:after {
            position: absolute;
            display: block;
            right: 0;
            margin-top: -1px;
            width: 0;
            height: 3px;
            content: "";
            background: #fff;
            transition: width 0.25s ease;
        }

        &:active,
        &:focus,
        &:hover {
            color: $white;
        }

        &:hover:after {
            left: 0;
            width: 100%;
            background-color: $white;
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-overlay {
        &__wrapper {
            padding-top: 4rem;
        }

        &__list {
            padding-bottom: 0.5rem;
        }

        &__link {
            font-size: 1rem;
        }
    }
}
