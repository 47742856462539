.ijv-separator {
    margin-top: -0.5rem;
    margin-bottom: 0.25rem;
    border-top: 3px solid rgba($black-base, 1);
    width: 100%;

    &--small {
        margin-top: 0.5rem;
        border-top: 1px solid rgba($black-base, 0.25);
        width: 25%;
    }
}
