.ijv-banner {
    position: relative;

    &__container {
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        border: $default-border;
        width: 100%;
        height: 4rem;
        padding: 0.5rem;
        overflow: hidden;
        white-space: nowrap;
        background-color: $deep-black;
        transform: translateZ(0);
        z-index: 2;

        &-sliding {
            position: inherit;
            margin-top: -0.25rem;

            .banner-stripe {
                margin: 0.5rem 0;

                &__sliding-text {
                    display: inline-block;
                    font-size: 1.5rem;
                    font-weight: 500;
                    color: $main-background-color;

                    .separator {
                        display: inline-block;
                        padding: 0 1.75rem;

                        &__line {
                            display: inline-block;
                            width: 0.75rem;
                            height: 1rem;
                            background-color: $main-background-color;
                            transform: skew(-50deg);
                        }

                        &__line:not(:first-child) {
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }

        &-rolling {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-top: -1.125rem;

            .message-block {
                font-size: 2rem;
                font-weight: 300;
                text-transform: capitalize;
                color: #fff;
            }

            .message-static {
                display: inline-block;
                height: 50px;
            }

            .container-message-dynamic {
                display: inline-block;
                overflow: hidden;
                position: relative;
                height: 50px;
                top: 14px;
                line-height: 50px;
            }

            .text-highlighted {
                @include animation-browsers(move 5s infinite 1s);
                position: relative;
                top: 0;
                left: 0;
                font-weight: 900;
                color: $main-background-color;
                white-space: nowrap;
            }

            @include animation-keyframes(move) {
                0% {
                    top: 0px;
                }

                20% {
                    top: -50px;
                }

                40% {
                    top: -100px;
                }

                60% {
                    top: -150px;
                }

                80% {
                    top: -200px;
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-banner {
        margin-bottom: 0;

        &__container {
            &-rolling {
                margin-top: -1.25rem;

                .message-block {
                    font-size: 1.375rem;
                }

                .container-message-dynamic {
                    top: 17px;
                    line-height: 50px;
                }
            }
        }
    }
}
