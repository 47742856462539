*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    margin: 0px;
    padding: 0px;
    border: 0px;
    box-sizing: border-box;
}

body {
    @include Inter;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ijv-body {
    @include flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
    z-index: 1;
    overflow-x: hidden;

    &__container-main {
        position: relative;
        margin-top: 6rem;
        background-color: $white;
        color: $text-base;
    }
}

@include media-breakpoint-up(md) {
    .ijv-body {
        display: -ms-grid;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas:
            "hd"
            "main"
            "ft";
    }
}

@include media-breakpoint-down(xs) {
    .ijv-body {
        &__container-main {
            margin-top: 4rem;
        }
    }
}

a {
    color: $text-base;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}

button:focus {
    outline: 0;
}
