.ijv-link {
    cursor: pointer;

    &__header {
        text-transform: capitalize;

        &:hover,
        &:focus,
        &:active {
            color: $text-base;
            text-decoration: underline;
        }
    }

    &__text {
        box-shadow: inset 0 -3px 0 $brand-first;

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($brand-first, 0.7);
            color: $text-base;
            transition: background-color 0.2s ease-in;
            box-shadow: none;
        }
    }

    &__headline {
        box-shadow: none;
        color: $deep-black;

        &:hover,
        &:focus,
        &:active {
            background-color: unset;
            color: $deep-black;
            text-decoration: underline;
            box-shadow: none;
        }

        &--white {
            color: $white;

            &:hover,
            &:focus,
            &:active {
                background-color: unset;
                color: $white;
                text-decoration: underline;
                box-shadow: none;
            }
        }
    }
}
