.ijv-block {
    display: block;
    width: 100%;
    height: auto;

    &__disclaimer {
        display: flex;
        justify-content: center;
        margin: 4rem 0;

        &__wrapper {
            margin: 2rem 0;
            border-radius: 1rem;
            border: $default-border;
            width: 100%;
            max-width: 52rem;
        }
    }

    &__container {
        @include flex;
        justify-content: center;
    }

    &__animation-shapes-wrapper {
        margin: 0 auto;
        width: 100%;
        max-width: 90rem;

        .animation-shapes {
            @include margin-topbot-same(0.5rem);
            height: 14rem;
            background: $black-base;
            overflow: hidden;
        }
    }

    &__animation-float-icons {
        width: 100%;
        height: 25rem;
        background: lighten($black-base, 3%);
        background-image: url("img/pattern_buried.png");
    }

    &__animation-waves {
        position: relative;
        width: 100%;
        height: 25rem;
        overflow: hidden;
        background-color: #163040;
    }
}

@include media-breakpoint-down(lg) {
    .ijv-block {
        &__disclaimer {
            &__wrapper {
                @include margin-topbot-same(1rem);
            }
        }

        &__animation-shapes-wrapper {
            .animation-shapes {
                height: 12rem;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-block {
        &__disclaimer {
            &__wrapper {
                margin: 1rem;
            }
        }

        &__animation-shapes-wrapper {
            .animation-shapes {
                height: 9rem;
            }
        }

        &__animation-float-icons {
            @include margin-topbot-same(0.5rem);
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-block {
        &__animation-shapes-wrapper {
            .animation-shapes {
                height: 7rem;
            }
        }

        &__animation-float-icons {
            @include margin-topbot-same(0.5rem);
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-block {
        &__disclaimer {
            &__wrapper {
                margin: 1rem 0.5rem 0.5rem 0.5rem;
            }
        }

        &__animation-float-icons,
        &__animation-waves {
            margin-top: 0.5rem;
            margin-bottom: 0;
        }

        &__animation-shapes-wrapper {
            .animation-shapes {
                margin-top: 0.5rem;
                margin-bottom: 0;
                height: 4rem;
            }
        }
    }
}
