.ijv-body {
    &__container-main {
        .ijv-index {
            position: relative;
            z-index: 2;

            &__cases {
                position: inherit;
                margin: 0 auto;
                max-width: 70rem;
                height: auto;
                padding: 0 0 12rem 0;
                z-index: 2;

                &__section-title {
                    display: block;
                    margin-top: 6rem;
                    margin-bottom: 3rem;
                    width: 100%;
                    font-size: 4rem;
                    font-weight: 900;
                    text-align: left;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .ijv-body {
        &__container-main {
            .ijv-index {
                &__cases {
                    padding: 0 2rem 12rem 2rem;

                    &__section-title {
                        margin-top: 6rem;
                    }

                    .ijv-separator {
                        margin-top: 0.5rem;
                        margin-bottom: 0.25rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-body {
        &__container-main {
            .ijv-index {
                &__cases {
                    padding: 0 2rem 12rem 2rem;

                    &__section-title {
                        margin-top: 4rem;
                    }

                    .ijv-separator {
                        margin-top: 0.5rem;
                        margin-bottom: 0.25rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-body {
        &__container-main {
            .ijv-index {
                &__cases {
                    padding: 0 2rem 10rem 2rem;

                    &__section-title {
                        margin-top: 3rem;
                        font-size: 3rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-body {
        &__container-main {
            .ijv-index {
                display: block;

                &__cases {
                    padding: 0 1rem 10rem 1rem;

                    &__section-title {
                        margin-top: 3rem;
                        font-size: 2rem;
                    }

                    .ijv-separator {
                        margin-top: 0.25rem;
                    }
                }
            }
        }
    }
}
