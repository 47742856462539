.ijv-collapse-box {
    @include flex;
    @include EBGaramond;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 5rem 0;
    width: 100%;
    max-width: 38rem;
    padding: 1rem;
    background-color: lighten($black-base, 82%);
    font-weight: 400;

    &__head {
        @include flex;
        justify-content: flex-start;
        width: 100%;
        padding: 1rem 0 1rem 1.75rem;

        &:hover,
        &:focus,
        &:active {
            cursor: pointer;

            .ijv-case__header {
                background-color: $black-base;
                color: $white;
                transition: background-color 0.2s ease-in;
            }
        }

        &--no-link {
            &:hover,
            &:focus,
            &:active {
                cursor: default;

                .ijv-case__header {
                    background-color: transparent;
                    color: $text-base;
                    transition: none;
                }
            }
        }

        .ijv-case__header {
            margin: 0;
            font-weight: 800;
            font-size: 1.75rem;
            box-shadow: inset 0 -3px 0 $black-base;

            &--no-link {
                box-shadow: none;
            }
        }
    }

    &__body {
        padding: 1rem 2rem 0 2rem;

        .ijv-case__text {
            max-width: none;
            line-height: 1.75rem;

            &:last-of-type {
                margin-bottom: 1.25rem;
            }
        }
    }

    &--no-link {
        margin: 2rem 0 3rem 0;
    }
}

@include media-breakpoint-down(sm) {
    .ijv-collapse-box {
        &__head {
            padding: 1.75rem;

            .ijv-case__header {
                font-size: 1.5rem;
            }
        }

        &__body {
            padding: 0 2rem; 
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-collapse-box {
        margin: 2rem 0 4rem 0;
        padding: 0;
        font-size: 1.25rem;

        &__head {
            padding: 1.75rem;
        }

        &__head.btn {
            white-space: normal;

            .ijv-case__header {
                font-size: 1.125rem;
            }
        }

        &__body {
            padding: 0 2rem;

            .ijv-case__text {
                line-height: 1.75rem;
            }
        }
    }
}
