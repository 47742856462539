.ijv-404 {
    @include flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 4rem;
    z-index: 1;

    &__terminal {
        width: 40rem;
        min-height: 20rem;
        height: auto;
        border-radius: 0.5rem;
        background-color: rgba(51, 51, 51, 0.95);

        &-header {
            padding: 0.25rem 1rem;
            border-radius: 0.5rem 0.5rem 0 0;
            border-bottom: 1px solid #888888;
            background: #ccc linear-gradient(to bottom, #ebebeb, #d5d5d5);
            color: #3f3f43;
            text-align: center;
        }

        &-content {
            @include PressStart2P;
            padding: 2rem 4rem;
            font-size: 1rem;
            color: $brand-first;
            line-height: 1.5;

            &__text {
                display: inline-block;
                margin: 0.5rem 0.5rem 1.5rem 0.5rem;

                &::after {
                    display: inline-block;
                    margin-left: 3px;
                    width: 8px;
                    height: 20px;
                    background-color: $brand-first;
                    content: "";
                    animation: blink 0.5s infinite;
                }

                &--title {
                    @include PressStart2P;
                    padding: 1rem 0;
                    font-size: 2rem;
                    text-align: center;
                }
            }

            &__blinking::after {
                height: 12px;
                width: 6px;
            }
        }
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    49% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@include media-breakpoint-down(lg) {
    .ijv-404 {
        margin: 2rem 4rem;

        &__terminal {
            width: 40rem;
            min-height: 20rem;
            height: auto;

            &-content {
                font-size: 1rem;
                padding: 2rem 4rem;

                &__text {
                    margin: 0.5rem 0.5rem 1.5rem 0.5rem;

                    &--title {
                        padding: 1rem 0;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .ijv-404 {
        margin: 1.5rem 3rem;

        &__terminal {
            width: 80%;
            min-height: 20rem;
            height: auto;

            &-content {
                font-size: 1rem;
                padding: 1.5rem 3rem;

                &__text {
                    margin: 0.5rem 0.5rem 1.5rem 0.5rem;

                    &--title {
                        padding: 1rem 0;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .ijv-404 {
        margin: 1rem 1rem 2rem 1rem;

        &__terminal {
            width: 100%;
            min-height: 22rem;
            height: auto;

            &-content {
                font-size: 1rem;
                padding: 1rem 2rem;

                &__text {
                    margin: 0.5rem 0.5rem 1.5rem 0.5rem;

                    &--title {
                        padding: 1rem 0;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .ijv-404 {
        margin: 1rem 1rem 2rem 1rem;

        &__terminal {
            width: 100%;
            min-height: 22rem;
            height: auto;

            &-content {
                font-size: 1rem;
                padding: 1rem 2rem;

                &__text {
                    margin: 0.5rem 0.5rem 1.5rem 0.5rem;

                    &--title {
                        padding: 1rem 0;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}
